<template >
  <v-app>
    <v-main class="primary">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <!-- Announce -->
            <!-- <v-row justify="center">
              <v-cols cols="12" sm="10" md="8" lg="6" xl="4">
                <v-card  class="px-10 mb-5">
                  <v-card-text>
                    <div class="text-h6 red--text my-5 text-center">
                      TOT ใช้งานได้ปกติแล้วนะคะ</br>
                      <a href="https://tot-permtang.web.app">tot-permtang.web.app</a></br>
                      ทางเราจึงขอปิดเว็บ TOT ในวันที่ 14 ก.ย. นี้ค่า
                    </div>
                  </v-card-text>
                </v-card>
              </v-cols>
            </v-row> -->

            <v-row justify="center">
              <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                <v-card class="px-5 px-md-10" tile>
                  <v-card-text class="text-left py-5 px-md-15">
                    <div class="d-flex justify-center mt-1">
                      <v-img
                        src="../assets/images/logo.jpg"
                        width="50%"
                        max-width="250px"
                      ></v-img>
                    </div>

                    <div class="text-h4 primary--text my-5 text-center">
                      ล็อคอินเข้าสู่ระบบ
                    </div>

                    <form @submit.prevent="login">
                      <v-text-field
                        class="mb-0"
                        placeholder="ชื่อผู้ใช้งาน (Username)"
                        background-color="white"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                        name="username"
                        v-model="form.username"
                        :error-messages="
                          $v.form.username.$error ? $error_text : ''
                        "
                      ></v-text-field>

                      <v-text-field
                        class="mb-0"
                        placeholder="รหัสผ่าน"
                        background-color="white"
                        outlined
                        dense
                        prepend-inner-icon="mdi-lock-open"
                        name="password"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="form.password"
                        :error-messages="
                          $v.form.password.$error ? $error_text : ''
                        "
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                      <span class="red--text" v-if="loginError">{{
                        loginError
                      }}</span>

                      <v-btn
                        color="primary"
                        elevation="0"
                        block
                        type="submit"
                        :disabled="loading"
                        :loading="loading"
                      >
                        เข้าสู่ระบบ
                      </v-btn>
                    </form>

                    <div class="pt-5">
                      <v-btn
                        color="error"
                        block
                        href="https://manual.permtang.com/documentation/billing/"
                        target="_blank"
                      >
                        <v-icon left large class="pr-5"
                          >mdi-book-open-variant</v-icon
                        >
                        คู่มือการใช้โปรแกรม
                      </v-btn>
                    </div>

                    <div class="mt-5 text-center mb-8">
                      <span class="mr-5 text-h6">ยังไม่มีบัญชีกับเรา ?</span>
                      <router-link to="/register" class="text-h6 primary--text">
                        สมัครใช้งานฟรี
                      </router-link>
                    </div>

                    <v-divider />

                    <div class="text-right mt-2">
                      <span class="mr-5">Line id: @P2277</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div class="text-center font-weight-light white--text mt-8">
              2020 YAKYAI SOFT .CO.LTD . ALL RIGHT RESERVE
            </div>
            <div class="text-center primary--text">
              V.06/11/2567 package new bill 
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

    


<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  data() {
    return {
      loginError: false,
      loading: false,
      showPassword: false,
      form: {
        username: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      username: { required },
      password: { required },
    },
  },
  created() {
    const tokens = ['bo_access_token', 'access_token'];
    tokens.forEach((token) => {
      if (localStorage.getItem(token)) {
        console.log(`remove ${token} in localStorage`);
        localStorage.removeItem(token);
      }
    });
  },
  methods: {
    async login() {
      this.loginError = false;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.loading = true;
      const body = {
        token: this.$jwt.sign(this.form, this.$privateKey, {
          noTimestamp: true,
        }),
      };

      try {
        const response = await this.$axios.post(
          `${this.$baseUrl}/users/authen`,
          body
        );
        localStorage.setItem('access_token', `Bearer ${response.result}`);
        //await this.$store.dispatch('onReload');
        await this.$store.dispatch('getBranchs');
        await this.$store.dispatch('getMyProfile');
        await this.$store.dispatch('getGroupPermissions');
        this.$router.replace('/pages/document/quotation/detail');
      } catch (error) {
        this.loginError =
          error.response?.data?.error_message || 'An error occurred';
        console.error('Login error:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>